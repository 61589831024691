@import 'src/styles/variables';

.back-button{
    margin-right: 10px;
  }
 
.titlediv{
  font-size: 20px;
  color: $mainBgColor;
}

// Subtitle for tables, subsections
.subtitle{
  font-size: 15px;
  font-weight: 500;
  color: $darkModeText;
  // margin-left: 8px;
  margin-bottom: 5px;
}

// Colors for active and inactive areas (text, drag&drop) - excluded buttons
.inactivecolor {
  color: $inactiveColor;
  }
.activecolor {
  color: $activeColor;
}

.mat-datepicker-toggle {
  margin-top: -12px !important;
}

.selected-menu {
  background-color: $tileSelection;
}
.deselected-menu {
  background-color: white;
}
.cdk-drag-placeholder {
  opacity: 0;
}
.cdk-drag-handle{
  position: relative;
  top: -70px;
  left: 15px;
}
// .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
//   // background-color: $greyColorLight;
//   background-color: $greyColorExtraLight;
//   height: 48px;
//   z-index: -1;
//   border-bottom: 3px solid $brandColor;
//   color: $primaryText;
//   font-weight: 800;
// }

.mat-mdc-tab.mdc-tab.mdc-tab.mdc-tab--active {
  background-color: #e1e1e1;
}

 .mat-mdc-tab.mdc-tab.mdc-tab {
    background-color: #f1f1f1;
 }

 .mat-mdc-select-disabled .mat-mdc-select-value {
    color: $greyColorDark;
 }
 .mat-mdc-tab-labels {
  border-bottom: 1px solid #dfdfdf;
}
// .mdc-tab__text-label {
//   letter-spacing: normal;
// }
