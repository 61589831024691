// Import library functions for theme creation.
@import '@angular/material/theming';
// Include non-theme styles for core.
@include mat-core();

/*
new version: see https://levelup.gitconnected.com/defining-your-own-theme-in-angular-material-8a4a6ffad400
@use '@angular/material' as mat;
@include mat.core();

$bluesky-primary: mat.define-palette(mat.$indigo-palette);
$bluesky-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// The warn palette is optional (defaults to red).
$bluesky-warn: mat.define-palette(mat.$red-palette);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$bluesky-theme: mat.define-light-theme((
  color: (
    primary: $bluesky-primary,
    accent: $bluesky-accent,
    warn: $bluesky-warn,
  )
));

*/


// Define your application's custom theme.
// $primary: mat-palette($mat-indigo);
// $accent:  mat-palette($mat-pink, A200, A100, A400);
// $theme: mat-light-theme($primary, $accent);


$primary: mat-palette($mat-deep-orange);
$accent: mat-palette($mat-deep-orange, 100, 200, 300);

//$warn: mat-palette($mat-brown, 100, 200, 300);
//$background-color: map_get($mat-blue-grey, 50);

$theme: mat-light-theme($primary, $accent);
// Insert custom background color
/*
$background: map-get($theme, background);
$background: map_merge($background, (background: $background-color));
$theme: map_merge($theme, (background: $background));
// Include theme styles for Angular Material components.
*/
@include angular-material-theme($theme);
//$isselected: map_get($mat-blue-grey, 300);
//$nonselected: map_get($mat-blue-grey, 100);


$alert-scale-5: rgb(250,10,10);
$alert-scale-4: rgb(250,80,10);
$alert-scale-3: rgb(250,180,10);
$alert-scale-2: rgb(200,250,10);
$alert-scale-1: rgb(050,250,10);


// Include theme styles for your custom components.
// @include candy-carousel-theme($theme);

$vistrace-primary: #74a892;
$vistrace-secondary: #f4f4f4;
$vistrace-terciary: #ececec;
$vistrace-selected: #f6f6f6;
$vistrace-hover-light: #adbbb5;
$vistrace-quinary: #638779;
$vistrace-hover-dark: #638779;
$vistrace-light-grey: #a2a2a2;
$vistrace-grey: #757575;

.mat-tree-node {
    background-color: rgb(241, 225, 225);
    margin: 2px;
    user-select: none;
    &.cdk-drag-preview { // while dragging
      @include mat-elevation(12);
    }
    &.cdk-drag-placeholder { // potential drop area
      opacity: 0;
    }
  }
  /* items moving away to make room for drop */
  .cdk-drop-list-dragging .mat-tree-node:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  /* item being dropped */
  .cdk-drag-animating {
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
  }

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-body-content {
    display: flex !important;
    flex-direction: row !important;
  }

  .mat-mdc-snack-bar-container {
    background: #5f6664;
    font-size: 20px;
    font-weight: 400;
    width: 100%;
  }

